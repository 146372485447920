import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Fade, Modal } from '@material-ui/core';
import content from './content.json';
import NavigationBar from '../navigation-bar/NavigationBar';
import Button from '../button/Button';
import { EButtonColor } from '../../enum/button';
import { IReduxState } from 'interface/i-redux-state';
import SVGLoader from 'component/svg/SVG';
import {
  EndingLogosPNG,
  InterieurBarSVG,
  StationCasseTeteSVG,
  StationCocktailSVG,
  StationDefiSVG,
  StationFrigoSVG,
  StationMiroirSVG,
  StationQuizSVG,
  StationSanteMentaleSVG,
  SurveyPosterSVG,
  SurveyWinSVG,
} from 'services/exports-list';
import googleTagManager from 'services/google-tag-manager';
import ActivityButton from 'component/activity-button/ActivityButton';
import { EBarScene, EBarSceneVape, EScene } from 'enum/scene';
import { EConsumption } from 'enum/user';
import sceneInteraction from 'services/scene-interaction';
import './ScreenBar.scss';

type cocktailText = {
  cocktailText: string;
};
type mirrorTexts = {
  mirrorTitle: string;
  mirrorText: string;
};
type defiTexts = {
  defiText: string;
};

interface IProps extends IReduxState {}

const today = new Date().getTime();
const validUntil = new Date('2024-03-07').getTime();
const isSurvey: boolean = process.env.REACT_APP_ENV === 'survey' && validUntil > today;
const ScreenBar = (props: IProps): React.ReactElement => {
  const state: IReduxState = useSelector((newState: IReduxState) => newState);
  const { currentBarScene, consumption } = props;
  const sceneList: Array<string> = Object.keys(state.consumption === EConsumption.VAPER ? EBarSceneVape : EBarScene);
  const [showInitalModal, setShowInitalModal] = useState(true);
  const [showModal, setShowModal] = useState<boolean>(true);
  const [sceneIndex, setSceneIndex] = useState<number>(0);
  const { cocktailText }: cocktailText =
    state.consumption === EConsumption.VAPER ? content.cocktail.vaper : content.cocktail.smoker;
  const { mirrorTitle, mirrorText }: mirrorTexts =
    state.consumption === EConsumption.VAPER ? content.mirror.vaper : content.mirror.smoker;
  const { defiText }: defiTexts = state.consumption === EConsumption.VAPER ? content.defi.vaper : content.defi.smoker;
  useEffect(() => {
    setSceneIndex(sceneList.indexOf(currentBarScene));
  }, [currentBarScene]);

  const moveBarScene = (direction: string) => {
    setShowModal(false);
    if (direction === 'previous') {
      sceneInteraction.animateToPrevScene();
    } else if (direction === 'next') {
      sceneInteraction.animateToNextScene();
    }
    setTimeout(() => {
      setShowModal(true);
    }, 2500);
  };
  const moveToScene = (scene: EScene) => {
    setShowModal(false);
    sceneInteraction.animateToScene(scene);
    setTimeout(() => {
      setShowModal(true);
    }, 2500);
  };
  const initialMoveBarScene = () => {
    setShowInitalModal(false);
    sceneInteraction.animateToNextScene();
  };

  const goToSceneOnClick = (scene: EScene) => {
    googleTagManager.onStandButtonClick(scene, state.completedBarScene.length);
    sceneInteraction.goToScreen(scene);
  };

  window.addEventListener('goToNextScene', (): void => {
    moveBarScene('next');
  });

  return (
    <>
      <div className="screen-bar">
        <div className="screen-bar__bar-wrapper">
          <div className="screen-bar__bar-wrapper-overflow">
            <Modal closeAfterTransition hideBackdrop open={currentBarScene === EScene.INSIDE_BAR && showInitalModal}>
              <Fade in={currentBarScene === EScene.INSIDE_BAR && showInitalModal}>
                <div className="activitydetail__modal modal">
                  <h2 className="modal__title">Bienvenue au Nico-Bar!</h2>
                  <p className="modal__text">Utilise les flèches pour naviguer dans le bar.</p>
                  <Button isStyled label="Compris!" onClick={() => initialMoveBarScene()} />
                </div>
              </Fade>
            </Modal>
            <ActivityButton
              id={EScene.QUIZZ}
              isActive={currentBarScene === EScene.QUIZZ && showModal}
              isCompleted={state.completedBarScene.includes(EScene.QUIZZ)}
              title="Quel cocktail Nico-Bar es-tu?"
              text="<p>Ouf, ça fait beaucoup d'infos à traiter tout d'un coup?</p><p>On t'a préparé un p'tit quiz léger pour finir ta visite au Nico-Bar tout en beauté!</p>"
              additionalText="Tu as déjà fait cette activité."
              extraClassName="screen-bar__quiz"
              SVGImage={StationQuizSVG}
              onClickEvt={() => goToSceneOnClick(EScene.QUIZZ)}
              label="Faire le quiz"
            />
            <ActivityButton
              id={EScene.TOYS_MACHINE}
              isActive={currentBarScene === EScene.TOYS_MACHINE && showModal}
              isCompleted={state.completedBarScene.includes(EScene.TOYS_MACHINE)}
              title="Es-tu tou-tourmenté?"
              text="<p>Wow, la belle machine à toutous! C'est ton jour de chance…peut-être! Attrape un toutou pour savoir quel est le p’tit souvenir du Nico-Bar que tu vas rapporter. Des montagnes russes d’émotions! Sélectionne ton toutou à l'aide des flèches et du bouton rouge.</p>"
              additionalText="Tu as déjà fait cette activité."
              extraClassName="screen-bar__mental"
              SVGImage={StationSanteMentaleSVG}
              onClickEvt={() => goToSceneOnClick(EScene.TOYS_MACHINE)}
              label="Attrape un toutou"
            />
            <ActivityButton
              id={EScene.MIRROR}
              isActive={currentBarScene === EScene.MIRROR && showModal}
              isCompleted={state.completedBarScene.includes(EScene.MIRROR)}
              title={mirrorTitle}
              text={mirrorText}
              additionalText="Tu as déjà fait cette activité."
              extraClassName="screen-bar__mirror"
              SVGImage={StationMiroirSVG}
              onClickEvt={() => goToSceneOnClick(EScene.MIRROR)}
              label="Entre dans les toilettes"
            />

            <ActivityButton
              id={EScene.COCKTAIL}
              isActive={currentBarScene === EScene.COCKTAIL && showModal}
              isCompleted={state.completedBarScene.includes(EScene.COCKTAIL)}
              title="Nos mocktails"
              text={cocktailText}
              additionalText="Tu as déjà fait cette activité."
              extraClassName="screen-bar__cocktail"
              SVGImage={StationCocktailSVG}
              onClickEvt={() => goToSceneOnClick(EScene.COCKTAIL)}
              label="Commande un mocktail"
            />
            <ActivityButton
              id={EScene.FREEZER}
              isActive={currentBarScene === EScene.FREEZER && showModal}
              isCompleted={state.completedBarScene.includes(EScene.FREEZER)}
              title="Le frigo de la dépendance"
              text="<p>Vite! Les clients sont en manque! Aide Nico, le barman, à aller chercher d’autres ingrédients dans la réserve! Trouve la combinaison du cadenas pour ouvrir le frigo. Tu n’as que 20 secondes avant que les clients perdent patience!</p>"
              additionalText="Tu as déjà fait cette activité."
              extraClassName="screen-bar__freezer"
              SVGImage={StationFrigoSVG}
              onClickEvt={() => goToSceneOnClick(EScene.FREEZER)}
              label="Débarre le frigo"
            />
            <ActivityButton
              id={EScene.VAPE_PUZZLE}
              isActive={currentBarScene === EScene.VAPE_PUZZLE && showModal}
              isCompleted={state.completedBarScene.includes(EScene.VAPE_PUZZLE)}
              title="Quelle maladresse!"
              text="<p>Deux clients fidèles dégustent leurs mocktails Nico-Bar à une table et discutent tranquillement de la vie quand tout à coup, tu fonces par accident dans leur table. BANG!</p>"
              additionalText="Tu as déjà fait cette activité."
              extraClassName={`screen-bar__puzzle ${consumption !== EConsumption.VAPER && 'disabled'}`}
              SVGImage={StationCasseTeteSVG}
              onClickEvt={() => consumption === EConsumption.VAPER && goToSceneOnClick(EScene.VAPE_PUZZLE)}
              label="Continuer"
            />
            <ActivityButton
              id={EScene.END}
              isActive={currentBarScene === EScene.END && showModal}
              isCompleted={state.completedBarScene.includes(EScene.END)}
              extraClassName="screen-bar__defi"
              SVGImage={isSurvey ? SurveyPosterSVG : StationDefiSVG}
              onClickEvt={() => {
                goToSceneOnClick(EScene.END);
              }}
            >
              <div className="screen-bar__defi-button__content">
                <h2>Le Défi Nico-Bar</h2>
                {isSurvey && (
                  <p className="screen-bar__survey-text">
                    Ha non tu nous quittes? Tu sais que tu peux revenir nous voir au bar virtuel aussi souvent que tu
                    veux! Mais juste avant que tu partes, si ça te tente de répondre à notre petit sondage sur le bar
                    virtuel, tu pourrais courir la chance de gagner un des 3 prix de 150$ en argent. As-tu 5-6 minutes?
                  </p>
                )}
                {!isSurvey && <p className="screen-bar__survey-text">{defiText}</p>}
                <img
                  src={isSurvey ? SurveyWinSVG : EndingLogosPNG}
                  aria-hidden="true"
                  alt="Cesse de fumer pendant 7 jours et cours la chance de remporter 1000$!"
                />

                {/* {process.env.REACT_APP_NICOBAR_MANY_CHAT_URL && (
                  <Button
                    link={process.env.REACT_APP_NICOBAR_MANY_CHAT_URL}
                    isStyled
                    label="M'inscrire sur Messenger"
                    onClick={() => googleTagManager.onDefiSubscribe(state.completedBarScene.length)}
                  />
                )}
                {process.env.REACT_APP_NICOBAR_INSTAGRAM_CHAT_URL && (
                  <Button
                    link={process.env.REACT_APP_NICOBAR_INSTAGRAM_CHAT_URL}
                    isStyled
                    label="M'inscrire sur Instagram Chat"
                    onClick={() => googleTagManager.onDefiSubscribe(state.completedBarScene.length)}
                  />
                )} */}

                <Button
                  isStyled
                  label="Poursuivre ta visite"
                  backgroundColor={EButtonColor.OUTLINE}
                  onClick={() => moveToScene(EScene.COCKTAIL)}
                />
              </div>
            </ActivityButton>
            <SVGLoader className="screen-bar__bar" src={InterieurBarSVG} />
          </div>
        </div>
      </div>
      <NavigationBar
        sceneIndex={sceneIndex}
        hasPointerEvents={showModal}
        onClickEvt={(direction: string) => moveBarScene(direction)}
      />
    </>
  );
};
export default ScreenBar;
